

.userAgreement {
  font-size: 16px;
  line-height: 40px;
  padding: 6px;
}

.title {
  margin-bottom: 10px;
}

.content {
  margin-bottom: 20px;
  font-size: 16px;
}