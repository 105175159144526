*{
    margin: 0;
    padding: 0;
}
body,html{
    background-color: #f8f8f8;
    color: #666666;
}
a {
    text-decoration: none;
  }

  /* 改变 a 标签的颜色 */
  a {
    color: #9e9e9e; /* 你可以选择任何你喜欢的颜色 */
  }

  /* 当鼠标悬停在 a 标签上时的样式 */
  a:hover {
    color: #0056b3; /* 悬停时的颜色 */
    text-decoration: none; /* 保持没有下划线 */
  }

  /* 点击后的 a 标签样式 */
  a:active {
    color: #0056b3; /* 点击时的颜色 */
    text-decoration: none; /* 保持没有下划线 */
  }

  /* 访问过的 a 标签样式 */
  a:visited {
    color: #9e9e9e; /* 已访问链接的颜色 */
    text-decoration: none; /* 保持没有下划线 */
  }