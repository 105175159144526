
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  padding: 0 250px;
  box-sizing: border-box;
  background: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  .logo{
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-between;
    cursor: pointer;
    span{
      padding: 4px;
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 0.5px solid #222222;
      font-weight: normal;
    }
  }
  .nav {
    cursor: pointer;
    font-weight: normal;
  }
}
.banner {
  width: 100%;
  height: 200px;
  background: url("../images/banner.png");
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  background-position: center;
  background-color: #FF7A38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  text-align: justify; /* 浏览器可能不支持 */
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #ffffff;
  .title2 {
    line-height: 50px;
    font-size: 20px;
    font-weight: 100;
  }
}
.main {
  width: 1000px;
  margin: 0 auto;

  .part1 {
    padding: 42px;
    border-radius: 10px;
    opacity: 1;
    margin: 20px 0;

    background: #ffffff;
    .section {
      margin-bottom: 32px;
      .title {
        font-family: Source Han Sans;
        font-size: 28px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #222222;
        margin-bottom: 20px;
        .icon {
          width: 8px;
          height: 25px;
          border-radius: 1px;
          opacity: 1;
          display: inline-block;
          margin-right: 10px;
          background: linear-gradient(180deg, #ff9c33 0%, #ff3d3d 100%);
        }
      }
      .desc {
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #757575;
      }
    }
  }

  .part2 {
    padding: 42px;
    border-radius: 10px;
    opacity: 1;
    margin: 20px 0;

    background: #ffffff;
    .title {
      position: relative;
      display: block;
      padding: 0 10px; /* 可以调整间距 */
      font-family: Source Han Sans;
      font-size: 34px;
      font-weight: bold;
      line-height: 30px;
      text-align: justify; /* 浏览器可能不支持 */
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #222222;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    .title::before,
    .title::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 30px; /* 横线的长度 */
      height: 1px; /* 横线的厚度 */
      background-color: #222222; /* 横线的颜色 */
    }

    .title::before {
      left: 350px;
    }

    .title::after {
      right: 350px;
    }
    .qrcode {
      width: 100%;
      display: flex;
      justify-content: center;
      justify-content: space-evenly;
      .word {
        font-family: Source Han Sans;
        font-size: 18px;
        font-weight: normal;
        line-height: 36px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #575757;
      }
      .left {
        width: 390px;
        height: 390px;
        border-radius: 10px;
        opacity: 1;

        background: #ffffff;

        box-sizing: border-box;
        border: 0.5px solid #b6b6b6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .qrimg {
          width: 230px;
          height: 230px;
        }
      }
      .right {
        width: 390px;
        height: 390px;
        border-radius: 10px;
        opacity: 1;

        background: #ffffff;

        box-sizing: border-box;
        border: 0.5px solid #b6b6b6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .wordContent{
          width: 350px;
          margin: 0 auto;
        }
        .qrimg {
          width: 230px;
          height: 230px;
        }
      }
    }
  }
  .about {
    position: absolute;
    top: 20px;
    right: 176px;
    width: 100px;
    height: 30px;
    cursor: pointer;
  }
}
.footer {
  margin: 0 auto;
  width: 100%;
  height: 307px;
  opacity: 1;
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0em;
  box-sizing: border-box;
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  color: #9e9e9e;
  background: #2c2c2c;
  padding: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  > div {
    margin-bottom: 39px;
    text-align: center;
  }
  .description {
    width: 452px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
    }
  }
  .record {
    margin-bottom: 0;

    /* 去掉所有 a 标签的下划线 */
    a {
      text-decoration: none;
    }

    /* 改变 a 标签的颜色 */
    a {
      color: #9e9e9e; /* 你可以选择任何你喜欢的颜色 */
    }

    /* 当鼠标悬停在 a 标签上时的样式 */
    a:hover {
      color: #0056b3; /* 悬停时的颜色 */
      text-decoration: none; /* 保持没有下划线 */
    }

    /* 点击后的 a 标签样式 */
    a:active {
      color: #0056b3; /* 点击时的颜色 */
      text-decoration: none; /* 保持没有下划线 */
    }

    /* 访问过的 a 标签样式 */
    a:visited {
      color: #9e9e9e; /* 已访问链接的颜色 */
      text-decoration: none; /* 保持没有下划线 */
    }
  }
}

