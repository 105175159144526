.privacy_policy {
    padding: 6px;
    font-size: 16px;
    line-height: 40rpx;
    .title {
        margin: 0 auto;
        text-align: center;
    }
    
    table {
        border-collapse: collapse;
        border-spacing: 0px;
        width: 100%;
        overflow: auto;
        break-inside: auto;
        text-align: left;
        word-break: break-all;
    }
    
    table tr:nth-child(2n),
    thead {
        background-color: #f8f8f8;
    }
    
    table tr {
        border-top: 1px solid #dfe2e5;
        margin: 0;
        padding: 0;
    }
    
    table tr th {
        font-weight: bold;
        border: 1px solid #dfe2e5;
        border-bottom: 0;
        margin: 0;
        padding: 6px 13px;
    }
    
    table tr td {
        border: 1px solid #dfe2e5;
        margin: 0;
        padding: 6px 13px;
    }
    
    th {
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
    }
    
    td {
        display: table-cell;
        vertical-align: inherit;
    }
    
    *,
     ::after,
     ::before {
        box-sizing: border-box;
    }
    
    @media (max-width: 767px) {
        table tr th:first-child,
        table tr td:first-child {
            width:64px;
        }
    }
}
